
import { Role } from '@/auth/role.enum';
import { filterValidPredictionStatuses } from '@/components/shared/utils';
import WorklistFilters from '@/components/worklist/WorklistFilters.vue';
import WorklistItem from '@/components/worklist/WorklistItem.vue';
import { resetDemoData } from '@/shared/mutations';
import { getUserPreferences, getVisitPayerRules, getVisitsByWorklistTab, getWorklistRefreshRate, getWorklistTabs } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useWorklistFiltersStore } from '@/stores/WorklistFiltersStore';
import { NavigationGuardMixin } from '@okta/okta-vue';
import { ApolloQueryResult } from 'apollo-client';
import { Mutation, Query, Visit, WorklistTab } from 'generated/graphql/graphql';

export default NavigationGuardMixin.extend({
    name: 'WorklistView',
    components: {
        WorklistItem,
        WorklistFilters,
    },
    data: () => ({
        selectedTabIndex: 0,
        tabs: [] as WorklistTab[],
        visits: [] as Visit[],
        storeLoading: false,
        visitLoading: false,
        hasMore: true,
        offset: 0,
        limit: 20,
        showFilters: false,
        isMenuOpen: false,
        userStore: useUserStore(),
        worklistFiltersStore: useWorklistFiltersStore(),
        featureStore: useFeatureStore(),
        visitsWithClinicalsDue: new Set(),
        refreshInterval: undefined as undefined | NodeJS.Timer,
        loadingPromise: null as Promise<ApolloQueryResult<Query>> | null,
    }),
    computed: {
        showChangeRole(): boolean | undefined {
            return this.userStore.availableRoles && this.userStore.availableRoles.length > 1;
        },
    },
    created() {
        this.enableAutoRefresh();
        this.userStore.$subscribe(() => {
            if (this.userStore.isInitialized === true) {
                this.refresh();
                this.enableAutoRefresh();
            }
        });

        this.worklistFiltersStore.$subscribe(() => {
            this.refresh();
        });
        this.storeLoading = true;
        this.loadUserPreferences();
    },
    activated() {
        this.refresh();
    },
    deactivated() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    },
    watch: {
        async visits() {
            if (this.visits.length) {
                await this.loadRules(this.visits.slice(-this.limit).map((visit) => +visit.id));
            }
        },
    },
    methods: {
        async enableAutoRefresh() {
            if (this.userStore.currentRole === Role.PHYSICIAN_ADVISOR) {
                const response = await this.$apollo.query<Query>({
                    query: getWorklistRefreshRate,
                });
                if (this.refreshInterval) {
                    clearInterval(this.refreshInterval);
                }
                this.refreshInterval = setInterval(() => this.refresh(), +(response.data?.config?.worklist?.refreshRate ?? 60000));
            } else {
                clearInterval(this.refreshInterval);
            }
        },
        selectTab() {
            this.$nextTick(async () => {
                this.visitsWithClinicalsDue.clear();
                this.visits = [];
                this.hasMore = true;
                this.offset = 0;
                await this.loadTabs();
                await this.loadVisits();
            });
        },
        async loadTabs() {
            this.visitLoading = true;
            return this.$apollo
                .query<Query>({
                    query: getWorklistTabs,
                    variables: {
                        role: this.userStore.currentRole?.key,
                        ...this.applyVisitFilters(),
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    this.tabs = response.data.worklistTabs;
                    this.visitLoading = false;
                });
        },
        async loadVisits() {
            if (this.loadingPromise) {
                await this.loadingPromise;
                return;
            }
            if (this.visitLoading || !this.hasMore) {
                return;
            }
            this.visitLoading = true;
            this.loadingPromise = this.$apollo.query<Query>({
                query: getVisitsByWorklistTab,
                variables: {
                    worklistTabName: this.tabs[this.selectedTabIndex].name,
                    ...this.applyVisitFilters(),
                    offset: this.offset,
                    limit: this.limit,
                },
                fetchPolicy: 'no-cache',
            });

            const response = await this.loadingPromise;
            this.visits = this.visits.concat(response.data.visitsByWorklistTab);
            this.offset += this.limit;
            this.hasMore = response.data.visitsByWorklistTab.length > 0;
            this.visitLoading = false;
            this.loadingPromise = null;

            return response;
        },
        async loadRules(visitIds: number[]) {
            const response = await this.$apollo.query<Query>({
                query: getVisitPayerRules,
                variables: {
                    filter: {
                        ids: visitIds,
                    },
                },
                fetchPolicy: 'no-cache',
            });
            response.data.visitPayerRules.forEach((visitPayerRule) => {
                if (visitPayerRule.clinicalsAreDue) {
                    this.visitsWithClinicalsDue.add(visitPayerRule.id);
                }
            });
            this.$forceUpdate();
        },
        applyVisitFilters() {
            this.worklistFiltersStore;
            return {
                filters: this.worklistFiltersStore.worklistFilterObject,
            };
        },
        async loadUserPreferences() {
            const response = await this.$apollo.query<Query>({
                query: getUserPreferences,
            });

            const preferences = response.data.userPreferences?.worklistFilters;
            const isPredictionRedesignEnabled = this.featureStore.isEnabled('PREDICTION_REDESIGN');
            const isEdModelEnabled = this.featureStore.isEnabled('ED_MODEL_FE');
            const predictedAdmitStatuses = filterValidPredictionStatuses(
                isPredictionRedesignEnabled,
                isEdModelEnabled,
                preferences?.predictedAdmitStatusCodes
            );

            this.worklistFiltersStore.$patch({
                isInitialized: true,
                autoSave: preferences !== null,
                selectedFromDate: preferences?.fromAdmitDate,
                selectedToDate: preferences?.toAdmitDate,
                selectedAdmitStatusCodes: preferences?.admitStatusCodes,
                selectedPredictedAdmitStatusCodes: predictedAdmitStatuses,
                selectedFacilityCodes: preferences?.facilityCodes,
                selectedLocationCodes: preferences?.locationCodes,
                selectedHospitalServiceCodes: preferences?.hospitalServiceCodes,
                selectedPayerCodes: preferences?.payerCodes,
                selectedPayerCategoryCodes: preferences?.payerCategoryCodes,
                selectedUmNurseOwnerIds: preferences?.umNurseOwnerIds,
                losGreaterThanGmlos: preferences?.losExceedsGmlos,
                activeDenials: preferences?.activeDenials,
                failedToSend: preferences?.failedToSend,
                readyToSend: preferences?.readyToSend,
                divisionCodes: preferences?.divisionCodes,
                isNotReviewed: preferences?.isNotReviewed,
            });
        },
        changeUserRole(role: Role): void {
            this.isMenuOpen = false;
            this.selectedTabIndex = 0;
            this.userStore.currentRole = role;
        },
        async resetDemoData() {
            await this.$apollo.mutate<Mutation>({
                mutation: resetDemoData,
            });
            this.refresh();
        },
        refresh(): void {
            if (this.userStore.isInitialized && this.worklistFiltersStore.isInitialized) {
                this.storeLoading = false;
                this.selectTab();
            }
        },
        toggleFilters(): void {
            this.showFilters = !this.showFilters;
        },
    },
});
